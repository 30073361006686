$blue: #3767AD;
$gray: #F5E5E7;

@mixin center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin resetButtonStyle {
  align-items: unset;
  background: unset;
  border: unset;
  color: unset;
  cursor: pointer;
  display: unset;
  font: unset;
  letter-spacing: unset;
  line-height: unset;
  margin: unset;
  padding: unset;
  text-align: unset;
  text-indent: unset;
  text-rendering: auto;
  text-shadow: unset;
  word-spacing: unset;
  writing-mode: unset;
}
@import "_normalize";
@import "_variables";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap&subset=latin-ext');


html {
  background: $blue;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  @include center;
  color: $gray;
  background: $blue;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  min-height: 100vh;
  padding: 10px;
  width: 100vw;
}

.header {
  &__title {
    display: none;
  }
}

.glass {
  @include center;
  &__actions {
    color: $gray;
    display: inline-flex;
    margin-top: 38px;
    & span {
      color: #000;
      padding: 0 5px;
    }
    &--remove {
      @include resetButtonStyle;
    }
    &--history {
      @include resetButtonStyle;
    }
  }
  &__buttonAdd {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: 18px;
    padding: 12px 32px;
  }
  &__history {
    display: none;
    line-height: 18px;
    &--clear {
      @include resetButtonStyle;
      color: yellow;
    }
    &--container {
      text-align: center;
    }
    &--view {
      display: initial;
    }
  }
  &__image {
    @include center;
    background-image: url(../assets/img/water-glass.png);
    height: 280px;
    margin-top: 10px;
    margin-bottom: 48px;
    position: relative;
    width: 280px;
  }
  &__value {
    color: #000000;
    font-size: 112px;
    padding-top: 114px;
    padding-bottom: 68px;
    text-align: center;
  }
}

.footer {
  line-height: 1.618;
  margin: 24px auto 10px;
  text-align: center;
  a {
    color: $gray;
  }
  &__heart {
    color: red;
  }
}